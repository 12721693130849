import React from 'react'
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form'
import { TextField, SelectField, DateField } from '@ftrustee/ui'
import validate from './validate'
import { Icon, IconButton } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import FormLabel from '@material-ui/core/FormLabel'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import { lifecycle } from 'recompose'
import { connect } from 'react-redux'

const options = [
  {
    value: 'blocked',
    label: 'Bloqueada'
  },
  {
    value: 'unblocked',
    label: 'Desbloqueada'
  }
]

// var clausulaPrazo = true

class renderFields extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      prazo: true,
      periodo: true,
      docProbatorios: true,
      declaracao: true,
      deducoesValor: true,
      previsaoRepasses: true
    }
  }

  onChange = e => {
    console.log(e)

    if (e.name === 'prazoComprovacao' && e.value === 'SIM') {
      this.setState({ prazo: false })
    } else if (e.name === 'prazoComprovacao' && e.value === 'NÃO') {
      this.setState({ prazo: true })
    } else if (e.name === 'periodVerificacao' && e.value === 'SIM') {
      this.setState({ periodo: false })
    } else if (e.name === 'periodVerificacao' && e.value === 'NÃO') {
      this.setState({ periodo: true })
    } else if (e.name === 'docComprobatorios' && e.value === 'SIM') {
      this.setState({ docProbatorios: false })
    } else if (e.name === 'docComprobatorios' && e.value === 'NÃO') {
      this.setState({ docProbatorios: true })
    } else if (e.name === 'declAssinada' && e.value === 'SIM') {
      this.setState({ declaracao: false })
    } else if (e.name === 'declAssinada' && e.value === 'NÃO') {
      this.setState({ declaracao: true })
    } else if (e.name === 'deducoes' && e.value === 'SIM') {
      this.setState({ deducoesValor: false })
    } else if (e.name === 'deducoes' && e.value === 'NÃO') {
      this.setState({ deducoesValor: true })
    } else if (e.name === 'repassesPeriodicos' && e.value === 'SIM') {
      this.setState({ previsaoRepasses: false })
    } else if (e.name === 'repassesPeriodicos' && e.value === 'NÃO') {
      this.setState({ previsaoRepasses: true })
    }
  }

  render() {
    return (
      <React.Fragment>
        <Grid container xs={12} spacing={24}>
          <Grid item xs={3} />
          <FormLabel component="legend">
            Destinação de recursos - Lastro
          </FormLabel>
          <TextField
            //   className={classes.mapFields}
            xs={12}
            name="descricao"
            placeholder="Descrição da Destinação dos Recursos"
          />
          <TextField
            //   className={classes.mapFields}
            xs={12}
            name="clausula"
            placeholder="Cláusula do TS/Contrato de Cessão/Contrato Lastro"
          />
          <SelectField
            xs={6}
            //   className={classes.mapFields}
            style={{ textAlign: 'center' }}
            name="prazoComprovacao"
            // placeholder="Prazo para comprovação"
            label="Prazo comprovação"
            options={['Sim', 'Não']}
            onChange={(event, value) =>
              this.onChange({ name: 'prazoComprovacao', value })
            }
          />
          <TextField
            //   className={classes.mapFields}
            xs={6}
            name="clausulaPrazo"
            label="Informe a clausula: "
            disabled={this.state.prazo}
          />
          <SelectField
            xs={6}
            //   className={classes.mapFields}
            style={{ textAlign: 'center' }}
            name="periodVerificacao"
            // placeholder="Periodicidade de verificação"
            label="Periodicidade de verificação"
            options={['Sim', 'Não']}
            onChange={(event, value) =>
              this.onChange({ name: 'periodVerificacao', value })
            }
          />
          <TextField
            //   className={classes.mapFields}
            xs={6}
            name="clausulaPeriod"
            label="Informe a clausula: "
            disabled={this.state.periodo}
          />
          <SelectField
            xs={6}
            //   className={classes.mapFields}
            style={{ textAlign: 'center' }}
            name="docComprobatorios"
            // placeholder="Documentos comprobatórios?"
            label="Documentos comprobatórios?"
            options={['Sim', 'Não']}
            onChange={(event, value) =>
              this.onChange({ name: 'docComprobatorios', value })
            }
          />
          <TextField
            //   className={classes.mapFields}
            xs={5}
            name="clausulaDoc"
            label="Informe a clausula: "
            disabled={this.state.docProbatorios}
          />
          <SelectField
            xs={12}
            //   className={classes.mapFields}
            style={{ textAlign: 'center' }}
            name="declAssinada"
            // placeholder="Envia declaração assinada?"
            label="Envia declaração assinada?"
            options={['Sim', 'Não']}
            onChange={(event, value) =>
              this.onChange({ name: 'declAssinada', value })
            }
          />
          <TextField
            //   className={classes.mapFields}
            xs={6}
            name="clausulaDeclaracao"
            label="Informe a clausula: "
            disabled={this.state.declaracao}
          />
          <SelectField
            //   className={classes.mapFields}
            xs={4}
            name="modelo"
            style={{ textAlign: 'center' }}
            // placeholder="Possui modelo?"
            label="Possui modelo?"
            options={['Sim', 'Não']}
            disabled={this.state.declaracao}
          />
          <Grid item xs={3} />
          <FormLabel component="legend" style={{ margin: '20px 0' }}>
            Destinação dos recursos - Securitizadora
          </FormLabel>
          <TextField
            //   className={classes.mapFields}
            xs={12}
            name="termoDefinido"
            placeholder="Termo definido e valor correspondente ao valor de cessão"
          />
          <TextField
            //   className={classes.mapFields}
            xs={12}
            name="clausulaTS"
            placeholder="Cláusula do TS e do Contrato de Cessão"
          />
          <SelectField
            xs={6}
            //   className={classes.mapFields}
            style={{ textAlign: 'center' }}
            name="deducoes"
            // placeholder="Prazo para comprovação"
            placeholder="Deduções do valor de cessão para despesas/fundo de reserva/etc"
            options={['Sim', 'Não']}
            onChange={(event, value) =>
              this.onChange({ name: 'deducoes', value })
            }
          />
          <TextField
            //   className={classes.mapFields}
            xs={6}
            name="clausulaValor"
            placeholder="Informe a clausula: "
            disabled={this.state.deducoesValor}
          />
          <SelectField
            xs={6}
            //   className={classes.mapFields}
            style={{ textAlign: 'center' }}
            name="repassesPeriodicos"
            // placeholder="Periodicidade de verificação"
            placeholder="Previsão de repasses periódicos para a cedente?"
            options={['Sim', 'Não']}
            onChange={(event, value) =>
              this.onChange({ name: 'repassesPeriodicos', value })
            }
          />
          <TextField
            //   className={classes.mapFields}
            xs={6}
            name="clausulaRepasses"
            placeholder="Informe a clausula: "
            disabled={this.state.previsaoRepasses}
          />
        </Grid>
      </React.Fragment>
    )
  }
}

const DestRecursosCriaCra = () => (
  <FieldArray name="destRecursosCricra" component={renderFields} />
)

export default DestRecursosCriaCra

// export default (DestRecursosFields = reduxForm({
//     form: 'DestRecursos', // a unique identifier for this form
//     validate
// })(DestRecursosFields))
