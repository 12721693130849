import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
// import { CalendarDaysEndPoint } from '../../utils/CalendarDay'
import axios from 'axios'

import { SelectField } from '@ftrustee/ui'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3
    // overflowX: 'auto',
  },
  table: {
    minWidth: 700
  }
})

let id = 0
function createData(dataverif, status, responsavel) {
  id += 1
  return { id, dataverif, status, responsavel }
}

const convertDateFormat = convertedData => {
  return convertedData
    .split('-')
    .reverse()
    .join('-')
}

const statusField = (member, num) => {
  let uniqueField = (
    <SelectField
      xs
      name={`${member}.status` + num}
      placeholder="Selecione um status"
      options={[
        'Pendente',
        'Cobrado',
        'Recobrado',
        'Enquadrado',
        'Desenquadrado',
        'AGD/PRORROGADO'
      ]}
    />
  )

  return uniqueField
}

const CalendarDays = () => {
  var m_data = new FormData()
  m_data.append('initialDate', '03/03/2015')
  m_data.append('finalDate', '03/03/2019')
  m_data.append('period', '3')
  var object = {}
  m_data.forEach(function(value, key) {
    object[key] = value
  })
  var json = JSON.stringify(object)
  axios({
    method: 'post',
    url: 'http://35.231.196.38/api/Calendar/Quarter',
    contentType: false,
    data: m_data
    //data: {
    //    "initialDate": "2015-04-02T22:35:33.721Z",
    //    "finalDate": "2019-04-02T22:35:33.724Z",
    //    "period": 3
    //},
  })
    .then(function(response) {
      console.log(response.data)
      console.log(response.status)
      console.log(response.statusText)
      console.log(response.headers)
      console.log(response.config)
    })
    .catch(function(error) {
      if (error.response) {
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
      } else if (error.request) {
        console.log(error.request)
      } else {
        console.log(error.message)
      }
    })
}

function TableCadControl(props) {
  const { classes } = props

  console.log(props.date)

  CalendarDays()

  let newDate

  if (props.date) {
    let firstDate = '2016-04-05'
    let initDate = props.date
    let origin = 'us-central1'

    newDate = convertDateFormat(initDate)

    // console.log(newDate)
  }

  const rows = [
    createData(
      newDate,
      statusField(props.member, 1),
      'fabio.ferreira@fincs.com.br'
    ),
    createData(
      '20-09-2017',
      statusField(props.member, 2),
      'naiara.camara@fincs.com.br'
    ),
    createData(
      '29-05-2018',
      statusField(props.member, 3),
      'fabio.ferreira@fincs.com.br'
    ),
    createData(
      '14-08-2018',
      statusField(props.member, 4),
      'fabio.ferreira@fincs.com.br'
    ),
    createData(
      '12-09-2018',
      statusField(props.member, 5),
      'antonyo.gianinni@fincs.com.br'
    ),
    createData(
      '23-04-2019',
      statusField(props.member, 6),
      'antonyo.gianinni@fincs.com.br'
    )
  ]

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="middle">Data verificação</TableCell>
            <TableCell align="middle">Status</TableCell>
            <TableCell align="middle">Responsável</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {row.dataverif}
              </TableCell>
              <TableCell align="middle">{row.status}</TableCell>
              <TableCell align="middle">{row.responsavel}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

TableCadControl.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TableCadControl)
