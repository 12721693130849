import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

import {
  GroupingState,
  IntegratedGrouping,
  RowDetailState,
  DataTypeProvider
} from '@devexpress/dx-react-grid'
import {
  Grid,
  Table,
  TableHeaderRow,
  TableGroupRow
} from '@devexpress/dx-react-grid-material-ui'

import ReferenceTabs from './ReferenceTabs'
import FormContainer from './FormContainer'

const DocumentList = ({
  classes,
  match,
  history,
  loading,
  security,
  columns,
  rows = [],
  currentTab,
  tabs = [],
  inventories = []
}) => {
  if (loading)
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </div>
    )

  return (
    <React.Fragment>
      <Helmet>
        <title>{security ? security.name : ''}</title>
      </Helmet>

      <Paper classes={{ root: classes.paper }}>
        <ReferenceTabs tabs={tabs} current={currentTab} />
        <Switch>
          <Route
            path={`${match.path}/new`}
            render={() => (
              <FormContainer
                type={security.type}
                currentTab={currentTab}
                inventories={inventories}
                onCancel={() => history.goBack()}
              />
            )}
          />
          <Route
            path={`${match.path}/:docId`}
            render={({ match }) => (
              <FormContainer
                type={security.type}
                currentTab={currentTab}
                inventories={inventories}
                documentId={match.params.docId}
                onCancel={() => history.goBack()}
              />
            )}
          />
          <Route
            path={`${match.path}/`}
            render={() => (
              <React.Fragment>
                <Grid rows={rows} columns={columns}>
                  <GroupingState grouping={[{ columnName: 'inventory' }]} />
                  <RowDetailState />
                  <IntegratedGrouping />
                  <DataTypeProvider
                    for={['id']}
                    formatterComponent={({ value }) => (
                      <React.Fragment>
                        <Link
                          to={`${match.url}/${value}${history.location.hash}`}>
                          <IconButton title="Editar">
                            <EditIcon />
                          </IconButton>
                        </Link>
                      </React.Fragment>
                    )}
                  />

                  <Table
                    columnExtensions={[
                      { columnName: 'pending', align: 'center' },
                      { columnName: 'issueDate', align: 'center' },
                      { columnName: 'id', width: 75, align: 'center' }
                    ]}
                  />
                  <TableHeaderRow />
                  <TableGroupRow />
                </Grid>

                <Button
                  variant="fab"
                  color="primary"
                  aria-label="add"
                  onClick={() =>
                    history.push(`${match.url}/new${history.location.hash}`)
                  }
                  className={classes.fab}>
                  <AddIcon />
                </Button>
              </React.Fragment>
            )}
          />
        </Switch>
      </Paper>
    </React.Fragment>
  )
}

DocumentList.propTypes = {
  classes: PropTypes.object.isRequired
}

const styles = theme => ({
  paper: {
    padding: theme.spacing.unit * 2,
    maxWidth: 1024
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2
  }
})

export default withStyles(styles)(DocumentList)
