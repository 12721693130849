import React from 'react'
import { lifecycle } from 'recompose'
import { FieldArray } from 'redux-form'

import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'

import { TextField, DateField } from '@ftrustee/ui'

import GuaranteeModals from '../../utils/GuaranteeModals'

const renderDescumprimento = lifecycle({
  componentDidMount() {
    this.props.fields.length === 0 && this.props.fields.push()
  }
})(({ fields, meta: { error, submitFailed } }) => {
  return (
    <React.Fragment>
      {fields.map((member, index) => (
        <Grid item xs={12} sm={9} key={index}>
          <Grid container spacing={16}>
            <DateField name={`${member}.date`} xs={2} label="Data" />
            <TextField name={`${member}.value`} label="Motivo" xs={9} />
            <Grid item xs={0}>
              <IconButton
                color="secondary"
                aria-label="adicionar"
                style={{ marginTop: 10 }}
                onClick={() =>
                  index > 0 ? fields.remove(index) : fields.push()
                }>
                <Icon>{index > 0 ? 'remove_circle' : 'add_circle'}</Icon>
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </React.Fragment>
  )
})

const DescumprimentoField = () => (
  <FieldArray name="descumprimento" component={renderDescumprimento} />
)

export default DescumprimentoField
