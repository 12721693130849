// import React from 'react'
// import PropTypes from 'prop-types'
// import { FieldArray } from 'redux-form'
// import { withStyles } from '@material-ui/core/styles'
// import { TextField, SelectField, NumberField, DateField } from '@ftrustee/ui'

// import Grid from '@material-ui/core/Grid'
// import Typography from '@material-ui/core/Typography'
// import TableCadControl from './TableCadControl'
// import { compose } from 'recompose'

// class renderFields extends React.Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       dynamicField: '',
//       diaUtilState: true,
//       diaMesState: true,
//       diasSemanaState: true,
//       date: ''
//     }
//   }

//   // handleInitialDate = e => {
//   //   console.log(e.value)

//   //   let eventReceived = new Date(e.value),
//   //     month = '' + (eventReceived.getMonth() + 1),
//   //     day = '' + (eventReceived.getDate() + 1),
//   //     year = eventReceived.getFullYear()

//   //   if (month.length < 2) month = '0' + month
//   //   if (day.length < 2) day = '0' + day

//   //   let formatedDate = [day, month, year].join('-')

//   //   console.log(formatedDate)

//   //   this.setState({ date: formatedDate })
//   // }

//   handleSelect = e => {
//     console.log(e.value)

//     if (e.value === 'ESCOLHER DIA') {
//       this.setState({ dynamicField: 0, diaMesState: false })
//     } else {
//       this.setState({ diaMesState: true })
//     }
//   }

//   handleOnChange = e => {
//     console.log(e.classes)

//     switch (e.value) {
//       case 'MENSAL':
//       case 'TRIMESTRAL':
//       case 'SEMESTRAL':
//       case 'ANUAL':
//       case 'BIENAL':
//       case 'TRIENAL':
//         this.setState({
//           dynamicField: 1,
//           diaUtilState: false,
//           diaMesState: true,
//           diasSemanaState: true
//         })
//         break
//       case 'QUANT DIAS ÚTEIS':
//         this.setState({ dynamicField: 2 })
//         break
//       case 'SEMANAL':
//       case 'QUINZENAL':
//         this.setState({
//           dynamicField: 3,
//           diasSemanaState: false,
//           diaUtilState: true,
//           diaMesState: true
//         })
//         break
//       default:
//         this.setState({ dynamicField: false })
//     }

//     console.log(this.state.dynamicField)
//   }

//   render() {
//     // console.log(this.props)

//     const { classes } = this.props

//     return (
//       <React.Fragment>
//         {/* <div className={classes.root}> */}
//         <Grid
//           container
//           spacing={16}
//           xs={12}
//           // direction="column"
//           // justify="space-between"
//           // alignItems="flex-start"
//         >
//           <SelectField
//             xs={3}
//             name={`${{ classes }.member}.periodicidade`}
//             placeholder="Periodicidade: "
//             options={[
//               'Mensal',
//               'Única',
//               'Anual',
//               'Trimestral',
//               'Semestral',
//               'Bienal',
//               'Semanal',
//               'Quinzenal',
//               'Diário',
//               'Trienal'
//             ]}
//             onChange={(event, value) => this.handleOnChange({ event, value, classes  })}
//             // onChange={(event, value) => (dynamicOption = value)}
//           />
//           <SelectField
//             xs={3}
//             name={`${{ classes }.member}.diaUtil`}
//             placeholder="Dia útil: "
//             options={[
//               '1° dia útil do mês',
//               'Útilmo dia útil do mês',
//               'Escolher dia'
//             ]}
//             disabled={this.state.diaUtilState}
//             onChange={(event, value) => this.handleSelect({ event, value })}
//           />
//           <NumberField
//             xs={2}
//             type="number"
//             name={`${{ classes }.member}.diaMes`}
//             InputProps={{ inputProps: { min: 0, max: 31, length: 2 } }}
//             placeholder="Escolha dia:"
//             helperText="Do dia 1 ao 31 **"
//             disabled={this.state.diaMesState}
//           />
//           <SelectField
//             xs={3}
//             name={`${{ classes }.member}.diasSemana`}
//             placeholder="selecione dia da semana:"
//             options={['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta']}
//             disabled={this.state.diasSemanaState}
//           />
//         </Grid>
//       </React.Fragment>
//     )
//   }
// }

// // TableTimeSheet.defaultProps = {
// //   rows: [],
// //   numero: '',
// //   dataInicio: '',
// //   dataFinal: ''
// // }

// // SimpleTable.propTypes = {
// //   classes: PropTypes.object.isRequired
// // }

// const TipoVerificacaoCtrl = () => (
//   <FieldArray name="tipoVerificacaoCtrl" component={renderFields} />
// )

// const FormView = compose(
//   reduxForm({ form: 'bond' }),
//   // withState('loading', 'isLoading', false),
//   connect(
//     (state, { autofill, options }) => {
//       const selector = formValueSelector('bond')
//       const { tipoVerificacaoCtrl} = selector(
//         state,
//         'tipoVerificacaoCtrl'
//       )

//       return {
//         tipoVerificacaoCtrl,
//         onChange: target => {

//         }
//       }
//     },
//     (dispatch, ownProps) => ({
//       isLoading: loading =>
//         dispatch({
//           type: 'APP_LOADING',
//           payload: loading
//         })
//     })
//   )
// )(TipoVerificacaoCtrl)

// export default TipoVerificacaoCtrl

import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { connect } from 'react-redux'
import { reduxForm, formValueSelector, FieldArray } from 'redux-form'

import { TextField, SelectField, NumberField, DateField } from '@ftrustee/ui'
import Grid from '@material-ui/core/Grid'

const FieldsTemplate = ({
  periodicidade,
  diaUtil,
  diaMes,
  diasSemana,
  diaUtilDisable,
  diaMesDisable,
  diasSemanaDisable,
  options,
  onChange,
  dataFill,
  ...props
}) => {
  // console.log('render#FormTemplate', props)

  // const { classes } = props

  console.log(diaUtilDisable)

  return (
    // !props.loading && (
    <form name={`${props.member}.camposVerificacao`}>
      <Grid container spacing={16} xs={12}>
        <SelectField
          xs={3}
          name={`${props.member}.periodicidade`}
          placeholder="Periodicidade: "
          options={[
            'Mensal',
            'Única',
            'Anual',
            'Trimestral',
            'Semestral',
            'Bienal',
            'Semanal',
            'Quinzenal',
            'Diário',
            'Trienal'
          ]}
          // onChange={(event, value) => this.handleOnChange({ event, value, classes })}
          onChange={(event, value) =>
            onChange({ name: `${props.member}.periodicidade`, value, ...props })
          }
          // onChange={(event, value) => (dynamicOption = value)}
        />
        <SelectField
          xs={3}
          name={`${props.member}.diaUtil`}
          placeholder="Dia útil: "
          options={[
            '1° dia útil do mês',
            'Útilmo dia útil do mês',
            'Escolher dia'
          ]}
          disabled={diaUtilDisable === true ? false : true}
          // onChange={(event, value) => this.handleSelect({ event, value })}
          onChange={(event, value) =>
            onChange({ name: `${props.member}.diaUtil`, value, ...props })
          }
        />
        <NumberField
          xs={2}
          type="number"
          name={`${props.member}.diaMes`}
          InputProps={{ inputProps: { min: 0, max: 31, length: 2 } }}
          placeholder="Escolha dia:"
          helperText="Do dia 1 ao 31 **"
          disabled={diaMesDisable === true ? false : true}
        />
        <SelectField
          xs={2}
          name={`${props.member}.diasSemana`}
          placeholder="selecione dia da semana:"
          options={['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta']}
          disabled={diasSemanaDisable === true ? false : true}
        />
      </Grid>
    </form>
  )
}

const TipoVerificacaoCtrl = compose(
  reduxForm({ form: 'tipoVerificacaoCtrl' }),
  // withState('loading', 'isLoading', false),
  connect(
    (state, { autofill, options }, ...props) => {
      const selector = formValueSelector('tipoVerificacaoCtrl')
      const {
        periodicidade,
        diaUtil,
        diaMes,
        diasSemana,
        diaUtilDisable,
        diaMesDisable,
        diasSemanaDisable
      } = selector(
        state,
        `${props.member}.periodicidade`,
        `${props.member}.diaUtil`,
        `${props.member}.diaMes`,
        `${props.member}.diasSemana`,
        diaUtilDisable,
        diaMesDisable,
        diasSemanaDisable
      )
      return {
        periodicidade,
        diaUtil,
        diaMes,
        diasSemana,
        diaUtilDisable,
        diaMesDisable,
        diasSemanaDisable,
        onChange: target => {
          console.log(target.value)

          switch (target.value) {
            case 'MENSAL':
            case 'TRIMESTRAL':
            case 'SEMESTRAL':
            case 'ANUAL':
            case 'BIENAL':
            case 'TRIENAL':
              // this.setState({
              //   dynamicField: 1,
              //   diaUtilState: false,
              //   diaMesState: true,
              //   diasSemanaState: true
              // })
              autofill('diaUtilState', false)
              autofill('diaMesState', true)
              autofill('diasSemanaState', true)
              break
            case 'QUANT DIAS ÚTEIS':
              // this.setState({ dynamicField: 2 })
              // autofill('dynamicField', 1)
              break
            case 'SEMANAL':
            case 'QUINZENAL':
              // this.setState({
              //   dynamicField: 3,
              //   diasSemanaState: false,
              //   diaUtilState: true,
              //   diaMesState: true
              // })
              autofill('diasSemanaState', false)
              autofill('diaUtilState', true)
              autofill('diaMesState', true)
              break
            case 'ESCOLHER DIA':
              autofill('diaMesDisable', true)
            default:
            // this.setState({ dynamicField: false })
            // autofill('dynamicField', false)

            // console.log(this.state.dynamicField)
          }
        }
      }
    },
    (dispatch, ownProps) => ({
      isLoading: loading =>
        dispatch({
          type: 'APP_LOADING',
          payload: loading
        })
    })
  )
)(FieldsTemplate)

export default TipoVerificacaoCtrl
