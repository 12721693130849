import React from 'react'
import { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { DialogContentText } from '@material-ui/core'

function Transition(props) {
  return <Slide direction="up" {...props} />
}

const GuaranteeConfirmBox = ({ children, enableRulesReset, ...props }) => {
  console.log(props)

  console.log(enableRulesReset)

  return (
    <div className="guaranteeConfirm">
      <Dialog
        open={props.state}
        onClose={!props.state}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        className="DialogContent">
        <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={() => props.setCloseState()}
            autoFocus>
            Cancelar
          </Button>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={() => props.confirm(enableRulesReset)}
            autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default GuaranteeConfirmBox
