import React from 'react'
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form'
import { TextField, SelectField, DateField } from '@ftrustee/ui'
import validate from './validate'
import { Icon, IconButton } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import { lifecycle } from 'recompose'
import { connect } from 'react-redux'

const options = [
  {
    value: 'blocked',
    label: 'Bloqueada'
  },
  {
    value: 'unblocked',
    label: 'Desbloqueada'
  }
]

// var clausulaPrazo = true

class renderFields extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      prazo: true,
      periodo: true,
      docProbatorios: true,
      declaracao: true
    }
  }

  onChange = e => {
    console.log(e)

    if (e.name === 'prazoComprovacao' && e.value === 'SIM') {
      this.setState({ prazo: false })
    } else if (e.name === 'prazoComprovacao' && e.value === 'NÃO') {
      this.setState({ prazo: true })
    } else if (e.name === 'periodVerificacao' && e.value === 'SIM') {
      this.setState({ periodo: false })
    } else if (e.name === 'periodVerificacao' && e.value === 'NÃO') {
      this.setState({ periodo: true })
    } else if (e.name === 'docComprobatorios' && e.value === 'SIM') {
      this.setState({ docProbatorios: false })
    } else if (e.name === 'docComprobatorios' && e.value === 'NÃO') {
      this.setState({ docProbatorios: true })
    } else if (e.name === 'declAssinada' && e.value === 'SIM') {
      this.setState({ declaracao: false })
    } else if (e.name === 'declAssinada' && e.value === 'NÃO') {
      this.setState({ declaracao: true })
    }
  }

  render() {
    return (
      <React.Fragment>
        <Grid container xs={12} spacing={24}>
          <Grid item xs />
          <TextField
            //   className={classes.mapFields}
            xs={12}
            name="descricao"
            placeholder="Descrição"
          />
          <TextField
            //   className={classes.mapFields}
            xs={12}
            name="clausula"
            placeholder="Cláusula da Escritura/Cártula"
          />
          <SelectField
            xs={6}
            //   className={classes.mapFields}
            style={{ textAlign: 'center' }}
            name="prazoComprovacao"
            // placeholder="Prazo para comprovação"
            label="Prazo comprovação"
            options={['Sim', 'Não']}
            onChange={(event, value) =>
              this.onChange({ name: 'prazoComprovacao', value })
            }
          />
          <TextField
            //   className={classes.mapFields}
            xs={6}
            name="clausulaPrazo"
            label="Informe a clausula: "
            disabled={this.state.prazo}
          />
          <SelectField
            xs={6}
            //   className={classes.mapFields}
            style={{ textAlign: 'center' }}
            name="periodVerificacao"
            // placeholder="Periodicidade de verificação"
            label="Periodicidade de verificação"
            options={['Sim', 'Não']}
            onChange={(event, value) =>
              this.onChange({ name: 'periodVerificacao', value })
            }
          />
          <TextField
            //   className={classes.mapFields}
            xs={6}
            name="clausulaPeriod"
            label="Informe a clausula: "
            disabled={this.state.periodo}
          />
          <SelectField
            xs={6}
            //   className={classes.mapFields}
            style={{ textAlign: 'center' }}
            name="docComprobatorios"
            // placeholder="Documentos comprobatórios?"
            label="Documentos comprobatórios?"
            options={['Sim', 'Não']}
            onChange={(event, value) =>
              this.onChange({ name: 'docComprobatorios', value })
            }
          />
          <TextField
            //   className={classes.mapFields}
            xs={5}
            name="clausulaDoc"
            label="Informe a clausula: "
            disabled={this.state.docProbatorios}
          />
          <SelectField
            xs={12}
            //   className={classes.mapFields}
            style={{ textAlign: 'center' }}
            name="declAssinada"
            // placeholder="Envia declaração assinada?"
            label="Envia declaração assinada?"
            options={['Sim', 'Não']}
            onChange={(event, value) =>
              this.onChange({ name: 'declAssinada', value })
            }
          />
          <TextField
            //   className={classes.mapFields}
            xs={6}
            name="clausulaDeclaracao"
            label="Informe a clausula: "
            disabled={this.state.declaracao}
          />
          <SelectField
            //   className={classes.mapFields}
            xs={4}
            name="modelo"
            style={{ textAlign: 'center' }}
            // placeholder="Possui modelo?"
            label="Possui modelo?"
            options={['Sim', 'Não']}
            disabled={this.state.declaracao}
          />
        </Grid>
      </React.Fragment>
    )
  }
}

const DestRecursosDebNp = () => (
  <FieldArray name="destRecursosFields" component={renderFields} />
)

export default DestRecursosDebNp

// export default (DestRecursosFields = reduxForm({
//     form: 'DestRecursos', // a unique identifier for this form
//     validate
// })(DestRecursosFields))
