import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import { logo } from '@ftrustee/ui'
import '../Home/App.css'

import FormGarantias from './FormView'

const styles = theme => ({
  title: {
    fontFamily: '"Insigna", "Roboto", "Helvetica", "Arial", sans-serif'
  },
  operationName: {
    // fontFamily: '"Insigna", "Roboto", "Helvetica", "Arial", sans-serif',
    textAlign: 'center',
    fontSize: '30px',
    fontWeight: 'bold',
    width: '100%',
    margin: '20px auto'
  },
  checkboxes: {
    width: '100%',
    // maxWidth: '700px',
    backgroundColor: theme.palette.background.paper,
    margin: '10px 0',
    textAlign: 'left',
    cursor: 'auto',
    paddingRight: '70%'
  },
  debOptions: {
    marginTop: '38px',
    marginLeft: '10px'
  },
  dividerFullWidth: {
    margin: `0 0 0 -35px`,
    width: '100%'
  },
  exerSocField: {
    display: 'flex',
    width: '140%'
  },
  exerSocLabel: {
    marginLeft: '40%',
    width: '100%'
  },
  alignLeftForm: {
    width: '45%',
    marginTop: '30px',
    textAlign: 'left',
    marginRight: '30%'
  },
  verificacaoField: {
    display: 'flex',
    width: '100%',
    marginLeft: '15px'
  },
  spacingBetweenFields: {
    margin: '20px 15px'
  },
  trimesterPadding: {
    padding: '0 14px'
  },
  dialogRecursos: {
    width: '500px',
    minHeight: '290px',
    margin: '65px'
  },
  dialogControles: {
    width: '800px',
    minHeight: '290px',
    margin: '0 80px 180px',
    paddingTop: '50px'
  },
  dateTimeField: {
    width: '30%',
    // marginRight: '90px',
    marginTop: '30px'
  },
  mapCheckboxGarantias: {
    width: '100%',
    marginLeft: '15px',
    marginTop: '30px'
  },
  selectModalGarantias: {
    width: '30%',
    marginTop: '20px'
  },
  identationContent: {
    marginLeft: '1%',
    marginTop: '20px',
    width: '100%'
  },
  leftPositioning: {
    textAlign: 'left'
  }

  // verifyTypeOptions:{
  //   width: '30%',
  //   marginTop: '20px',
  //   marginRight: '80px',
  //   float: 'left',
  //   display: 'inline'
  // }
  // ,
  // containerTipoSelect: {
  //   marginLeft: '40px',
  //   border: '1px solid black',
  //   width: '30%',
  //   height: '265px'
  // }
})

const Guarantee = ({ classes, match, companies, options, ...props }) => {
  let type

  if (props.initialValues) {
    // console.log(props.initialValues.type)
    type = props.initialValues.type
  } else {
    type = 'CARREGANDO...'
  }

  console.log(options)

  return (
    <div className="App">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <Typography
          variant="h5"
          color="inherit"
          classes={{ root: classes.title }}>
          Controle de Documentação
        </Typography>
      </header> */}
      {/* <p className="App-intro" /> */}
      <FormGarantias
        classes={{
          root: classes.checkboxes,
          operationName: classes.operationName,
          dividers: classes.dividerFullWidth,
          byChecked: classes.exerSocField,
          label: classes.exerSocLabel,
          alignLeftForm: classes.alignLeftForm,
          firstVerify: classes.verificacaoField,
          spacingBetweenFields: classes.spacingBetweenFields,
          trimesterPadding: classes.trimesterPadding,
          dialogRecursos: classes.dialogRecursos,
          dialogControles: classes.dialogControles,
          dateTimeField: classes.dateTimeField,
          mapFields: classes.mapCheckboxGarantias,
          debOptions: classes.debOptions,
          modalSelect: classes.selectModalGarantias,
          identationContent: classes.identationContent,
          left: classes.leftPositioning
          // verifyOption: classes.verifyTypeOptions,
          // containerTipoSelect: classes.containerTipoSelect
        }}
        company={props.location.state.title}
        type={type}
      />
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(Guarantee)
