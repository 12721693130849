import React from 'react'
import { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import { TextField } from '@ftrustee/ui'

function Transition(props) {
  return <Slide direction="up" {...props} />
}
// TRECHO UTILIZANDO REACT-HOOKS ( para stateless components)
//   export const ModalForm = (props, content) => {
//     const [opened, setCloseState] = useState(true)

//     console.log(props)

//     return (
//       <div className="auditPopUp">
//         <Dialog
//           open={opened}
//           onClose={() => setCloseState(!opened)}
//           TransitionComponent={Transition}
//           aria-labelledby="form-dialog-title"
//           keepMounted>
//           {content}
//           <Button
//             size="small"
//             color="primary"
//             variant="contained"
//             type="submit"
//             // onClick={() => setCloseState(!opened)}
//             onClick={() => setCloseState(!opened)}
//             autoFocus>
//             OK
//           </Button>
//         </Dialog>
//       </div>
//     )
//   }

const GuaranteeModals = ({ children, ...props }) => {
  console.log(props)

  return (
    <div className="guaranteePopUp">
      <Dialog
        open={props.state}
        onClose={!props.state}
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
        className="DialogContent">
        <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        {/* <DialogActions> */}
        <Button
          size="small"
          color="primary"
          variant="contained"
          type="submit"
          onClick={() => props.setCloseState()}
          autoFocus>
          OK
        </Button>
        {/* </DialogActions> */}
      </Dialog>
    </div>
  )
}

export default GuaranteeModals
