import React from 'react'
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form'
import { TextField, SelectField, DateField } from '@ftrustee/ui'
import validate from './validate'
import { Icon, IconButton } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import { lifecycle } from 'recompose'
import { connect } from 'react-redux'

// const renderField = ({ input, label, type, meta: { touched, error } }) => (
//   <div>
//     <label>{label}</label>
//     <div>
//       <input {...input} type={type} placeholder={label} />
//       {touched && error && <span>{error}</span>}
//     </div>
//   </div>
// )

const options = [
  {
    value: 'blocked',
    label: 'Bloqueada'
  },
  {
    value: 'unblocked',
    label: 'Desbloqueada'
  }
]

const renderFields = ({ fields, meta: { error } }) => (
  <React.Fragment>
    {/* <Grid container spacing={16}> */}
    {/* <button type="button" onClick={() => fields.push()}>
        Add Hobby
      </button> */}

    <Grid item style={{ textAlign: 'right', height: '6px' }}>
      <IconButton
        color="secondary"
        aria-label="adicionar"
        style={{ bottom: '47px', left: '5px' }}
        onClick={() => fields.push()}>
        <Icon>add_circle</Icon>
      </IconButton>
    </Grid>
    {fields.map((hobby, index) => (
      <Grid item style={{ paddingRight: '24px' }} key={index}>
        {/* <button
                    type="button"
                    title="Remove Hobby"
                    onClick={() => fields.remove(index)}
                /> */}
        <Grid container xs={12} spacing={24}>
          <Grid item xs />
          <DateField
            name={`${hobby}.date`}
            // component={renderField}
            label="Data"
            xs
          />
          <TextField
            name={`${hobby}.value`}
            type="text"
            // component={renderField}
            label="Motivo"
            xs
          />
          <SelectField
            name={`${hobby}.type`}
            label="Status"
            options={options}
            xs
          />
          <IconButton
            xs
            color="secondary"
            aria-label="adicionar"
            onClick={() => fields.remove(index)}>
            <Icon>remove_circle</Icon>
          </IconButton>
        </Grid>
      </Grid>
    ))}
    {/* {error && <li className="error">{error}</li>} */}
  </React.Fragment>
)

const renderMembers = lifecycle({
  componentDidMount() {
    this.props.fields.length === 0 && this.props.fields.push()
  }
})(({ fields, meta: { error, submitFailed }, ...props }) => {
  return (
    <div style={{ width: '100%', textAlign: 'left' }}>
      <Grid
        item
        xs={12}
        spacing={16}
        style={{ height: '5px', marginLeft: '210px' }}>
        <IconButton
          color="secondary"
          aria-label="adicionar"
          style={{ bottom: '40px' }}
          onClick={() => fields.push()}>
          <Icon>add_circle</Icon>
        </IconButton>
        {/* {submitFailed && error && <span>{error}</span>} */}
      </Grid>
      {fields.map((member, index) => (
        <ExpansionPanel
          defaultExpanded
          // expanded={}
          // className={props.classes.root}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Grid item xs={12} spacing={16}>
              <Typography variant="title">
                <TextField
                  className="custom-input"
                  name={`${member}.name`}
                  // type="text"
                  placeholder={'Nome ' + (index + 1)}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true
                  }}
                />
              </Typography>
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid item style={{ width: '100%' }} key={index}>
              {/* <h4>{index + 1}</h4> */}
              <Grid container xs={12} spacing={24}>
                <TextField
                  name={`${member}.name`}
                  type="text"
                  // component={renderField}
                  label="Nome"
                  xs
                  // onChange={(e) => handleNameAccount(e)}
                />
                <DateField
                  name={`${member}.date`}
                  // component={renderField}
                  label="Data"
                  xs
                />
                <TextField
                  name={`${member}.value`}
                  type="text"
                  // component={renderField}
                  label="Motivo"
                  xs
                />
                <SelectField
                  name={`${member}.type`}
                  label="Status"
                  options={options}
                  xs
                />
                <Grid item>
                  <IconButton
                    color="secondary"
                    aria-label="adicionar"
                    onClick={() => fields.remove(index)}>
                    <Icon>remove_circle</Icon>
                  </IconButton>
                </Grid>
              </Grid>
              <FieldArray name={`${member}.hobbies`} component={renderFields} />
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </div>
  )
})

let ContaVinculadaFields = props => {
  const { contaName, pristine, reset, submitting } = props

  return (
    // <Grid item xs={12}>
    <Grid container spacing={16}>
      <FieldArray
        name="members"
        // contaName={contaName}
        component={renderMembers}
      />
    </Grid>
    // </Grid>
  )
}

export default (ContaVinculadaFields = reduxForm({
  form: 'contaVinculada', // a unique identifier for this form
  validate
})(ContaVinculadaFields))

// const selector = formValueSelector('contaVinculada') // <-- same as form name
// ContaVinculadaFields = connect(state => {
//   // or together as a group
//   const contaName = selector(state, {member})
//   return {
//     contaName
//   }
// })(ContaVinculadaFields)

// export default ContaVinculadaFields
