import React from 'react'
import { useState } from 'react'
import PropTypes, { func } from 'prop-types'
import { withApollo } from 'react-apollo'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { gql } from 'apollo-boost'
import Grid from '@material-ui/core/Grid'
import { reduxForm, formValueSelector } from 'redux-form'

import {
  Form,
  TextField,
  SelectField,
  CheckboxField,
  NumberField,
  AutocompleteField,
  PhoneList,
  AddressList,
  DateField
} from '@ftrustee/ui'

import CadControlFields from './CadControlFields'
import DescumprimentoField from './DescumprimentoField'
import GuaranteeModals from '../../utils/GuaranteeModals'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Divider from '@material-ui/core/Divider'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import IconButton from '@material-ui/core/IconButton'
import CloudDownload from '@material-ui/icons/CloudDownload'
import Typography from '@material-ui/core/Typography'
import { ListItemText, ListItem, List, Select } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import ContaVinculadaFields from './ContaVinculadaFields'
import GuaranteeConfirmBox from '../../utils/GuaranteeConfirmBox'
import DestRecursosDebNp from './DestRecursosDebNp'
import DestRecursosCriaCra from './DestRecursosCriaCra'
import ContratoGarantiaFields from './ContratoGarantiaFields'

let enableRules = [
  {
    id: 0,
    diaUtilState: true,
    diaMesState: true,
    diasSemanaState: true,
    quantDiasState: true
  }
]

const FormTemplate = ({
  options,
  destRecursosFields,
  CadControlesChk,
  descricao,
  clausula,
  prazoComprovacao,
  fidejussoria,
  flutuante,
  quirografaria,
  real,
  subordinada,
  destRecursos,
  destRecursosChk,
  contrGarantia,
  contrGarantiaChk,
  cvChk,
  modalGarantias,
  confirmGarantias,
  showHideFormConta,
  tipoVerificacaoCtrl,
  cadControlFields,
  ControleCadastros,
  diaUtil,
  defaultCadForm,
  onClick,
  onChange,
  dataFill,
  ...props
}) => {
  // const fidejussoriaOption = fidejussoria ? 'Fidejussória' : ''

  // console.log(tipoVerificacao)

  const LabelMap = {
    textAlign: 'left',
    width: '100%'
  }

  const LabelContract = {
    textAlign: 'left',
    marginLeft: '1%',
    marginTop: '30px'
  }

  const IconContract = {
    marginTop: '15px'
  }

  const MapCheckGroup = {
    paddingLeft: '10%'
  }

  if (cadControlFields) {
    console.log(cadControlFields[0])
  }

  console.log(cadControlFields)

  // btnContColor = ""

  // if (descricao || docVerif) {
  //   btnContColor=
  // }

  const enableRulesReset = () => {
    enableRules = [
      {
        id: 0,
        diaUtilState: true,
        diaMesState: true,
        diasSemanaState: true,
        quantDiasState: true
      }
    ]
    console.log(enableRules)
  }

  const handlerControl = e => {
    console.log(e)

    if (e.index > 0) {
      e.enableRules.pop()
      e.fields.remove(e.index)
    } else {
      e.enableRules.push({
        diaUtilState: true,
        diaMesState: true,
        diasSemanaState: true,
        quantDiasState: true
      })
      e.fields.push()
    }
  }

  const handleChangeCadControl = e => {
    console.log(e.copyState)

    // if (e.name === 'periodicidade') {
    console.log(e.value)

    switch (e.value) {
      case 'MENSAL':
      case 'TRIMESTRAL':
      case 'SEMESTRAL':
      case 'ANUAL':
      case 'BIENAL':
      case 'TRIENAL':
        // e.enableRules.find(v => v.id === e.index).diaUtilState = false
        // e.enableRules.find(v => v.id === e.index).diaMesState = true
        // e.enableRules.find(v => v.id === e.index).diasSemanaState = true
        // e.enableRules.find(v => v.id === e.index).quantDiasState = true
        e.enableRules[e.index].diaUtilState = false
        e.enableRules[e.index].diaMesState = true
        e.enableRules[e.index].diasSemanaState = true
        e.enableRules[e.index].quantDiasState = true

        // console.log(cadControlFields)
        // console.log(e.value)
        break
      case 'QUANT DIAS ÚTEIS':
        break
      case 'DIA ÚTIL':
        e.enableRules[e.index].diaUtilState = false
        e.enableRules[e.index].diaMesState = true
        e.enableRules[e.index].diasSemanaState = true
        e.enableRules[e.index].quantDiasState = false
        break
      case '1° DIA ÚTIL DO MÊS':
      case 'ÚTILMO DIA ÚTIL DO MÊS':
        e.enableRules[e.index].diaUtilState = false
        e.enableRules[e.index].diaMesState = true
        e.enableRules[e.index].diasSemanaState = true
        e.enableRules[e.index].quantDiasState = true
        break
      case 'ESCOLHER DIA':
        e.enableRules[e.index].diaUtilState = false
        e.enableRules[e.index].diaMesState = false
        e.enableRules[e.index].diasSemanaState = true
        e.enableRules[e.index].quantDiasState = true
        break
      case 'ÚNICA':
      case 'DIÁRIO':
        e.enableRules[e.index].diaUtilState = true
        e.enableRules[e.index].diaMesState = true
        e.enableRules[e.index].diasSemanaState = true
        e.enableRules[e.index].quantDiasState = true
        break
      case 'SEMANAL':
      case 'QUINZENAL':
        e.enableRules[e.index].diaUtilState = true
        e.enableRules[e.index].diaMesState = true
        e.enableRules[e.index].diasSemanaState = false
        e.enableRules[e.index].quantDiasState = true
        break
      default:
    }
    // }
  }

  const statusFormConta = showHideFormConta ? <ContaVinculadaFields /> : false

  // const modalRecursos = destRecursos ? modalForm(props, contentModalDr) : false

  // const modalContrato = contrGarantia ? modalForm(props, contentModalCg) : false

  // let modalRecursos

  // const modalCaller = (e) => {

  //   if (e.name === 'destRecursos' && e.value) {
  //     modalRecursos = <GuaranteeModals content={contentModalDr}/>
  //   } else if (e.name === 'contrGarantia' && e.value) {

  //   } else if (e.name === 'CadControles' && e.value) {

  //   }
  // }

  // console.log(tipoVerificacaoCtrl)

  // if (cadControlFields) {
  //   Object.keys(cadControlFields).forEach(function (key, index) {
  //     if (cadControlFields[key]) {
  //       if (cadControlFields[key].periodicidade === 'MENSAL') {
  //         console.log(cadControlFields[key].periodicidade)
  //         // resetFields({ value: 'MENSAL', key, index })
  //       }
  //     }
  //   })
  // }

  return (
    <div className="guarantee-form">
      <Form {...props}>
        {modalGarantias}
        {/*{modalContrato} */}
        <FormLabel component="legend" className={props.classes.operationName}>
          {props.company}
        </FormLabel>
        <FormLabel
          component="legend"
          style={{ margin: '24px 0 0 15px' }}
          // className={props.classes.modalSelect}
        >
          Tipos de Instrumento:
        </FormLabel>
        {/* <SelectField
          xs={2}
          name="tipoInstrumento"
          placeholder="Escolha um tipo: "
          options={['Debêntures', 'NP', 'CRI', 'CRA']}
        /> */}
        <Typography
          // className={props.classes.debOptions}
          style={{ margin: '23px  0 0 10px' }}
          variant="body2"
          gutterBottom>
          {props.type}
        </Typography>
        <Grid container xs={12} spacing={24}>
          <FormLabel style={{ margin: '40px 0 35px 28px' }} component="legend">
            Tipos de Garantia:
          </FormLabel>
          <Typography
            className={props.classes.debOptions}
            variant="body2"
            gutterBottom>
            Real, Quirografária, Fidejussória, Subordinada
          </Typography>
        </Grid>
        {/* <FormControlLabel
          className={props.classes.root}
          control={
            <Typography
              className={props.classes.debOptions}
              variant="body2"
              gutterBottom>
              (em aberto para incluir lista no futuro)
            </Typography>
          }
          label="Agente de Garantia:"
          labelPlacement="start"
        /> */}
        {/* <FormControlLabel
          className={props.classes.spacingBetweenFields}
          control={
            <CheckboxField
              color="primary"
              // inputRef={this.checkboxRef}
              // disabled={!required}
              // name={`${name}.checked`}
              name="fidejussoria"
              id="fidejussoria"
            />
          }
          label="Fidejussória"
        /> */}
        {/* <FormControlLabel
          className={props.classes.spacingBetweenFields}
          control={
            <CheckboxField
              color="primary"
              // inputRef={this.checkboxRef}
              // disabled={!required}
              // name={`${name}.checked`}
              name="flutuante"
              id="flutuante"
            />
          }
          label="Flutuante"
        /> */}
        {/* <FormControlLabel
          className={props.classes.spacingBetweenFields}
          control={
            <CheckboxField
              color="primary"
              // inputRef={this.checkboxRef}
              // disabled={!required}
              // name={`${name}.checked`}
              name="quirografaria"
              id="quirografaria"
            />
          }
          label="Quirografária"
        /> */}
        {/* <FormControlLabel
          className={props.classes.spacingBetweenFields}
          control={
            <CheckboxField
              color="primary"
              // inputRef={this.checkboxRef}
              // disabled={!required}
              // name={`${name}.checked`}
              name="real"
              id="real"
            />
          }
          label="Real"
        /> */}
        {/* <FormControlLabel
          className={props.classes.spacingBetweenFields}
          control={
            <CheckboxField
              color="primary"
              // inputRef={this.checkboxRef}
              // disabled={!required}
              // name={`${name}.checked`}
              name="subordinada"
              id="subordinada"
            />
          }
          label="Subordinada"
        /> */}
        {/* <FormControlLabel
          className={props.classes.root}
          control={
            <Grid item xs={12}>
             <Grid container spacing={5}>
            <ContaVinculadaField />
            <FieldArraysForm/> 
             </Grid>
              </Grid>
          }
          label="Conta Vinculada"
        /> */}

        {/* <FormLabel
          component="legend"
          style={LabelMap}
          className={props.classes.mapFields}>
        </FormLabel>
        <div className={props.classes.identationContent}>
          <ContaVinculadaFields />
        </div> */}

        {/* <FormControlLabel
          className={props.classes.root}
          control={
            <Grid item xs={12}>
             <Grid container spacing={5}>
            <ContaVinculadaField />
            <FieldArraysForm/> 
             </Grid>
              </Grid>
          }
          label="Conta Vinculada"
        /> */}

        <CheckboxField
          color="primary"
          // inputRef={this.checkboxRef}
          // disabled={!required}
          // name={`${name}.checked`}
          name="cvChk"
          id="CheckConta"
          onChange={(event, value) =>
            onChange({ name: 'cvChk', value, ...props })
          }
        />
        <Button
          // className={props.classes.spacingBetweenFields}
          //   xs
          color={cvChk ? 'primary' : ''}
          disabled={cvChk ? false : true}
          name="contaVinculadaBtn"
          size="small"
          onClick={event => onClick({ name: 'contaVinculadaBtn', ...props })}>
          Conta Vinculada
        </Button>

        {statusFormConta}
        {/* <FormLabel
          component="legend"
          style={LabelMap}
          className={props.classes.mapFields}>
          Conta Vinculada
        </FormLabel>
        <div className={props.classes.identationContent}>
          <ContaVinculadaFields />
        </div> */}

        {/* <TextField xs={12} name="descumprimento:" label="Descumprimento:" /> */}
        <FormLabel
          component="legend"
          style={LabelMap}
          className={props.classes.mapFields}>
          Descumprimento:
        </FormLabel>
        <div className={props.classes.identationContent}>
          <DescumprimentoField />
        </div>
        {/* <FormControlLabel
            // className={props.classes.spacingBetweenFields}
            control={
              <div className={props.classes.spacingBetweenFields}>
                <Button
                className={props.classes.spacingBetweenFields}
                  xs
                  size="medium"
                  // color="secondary"
                  variant="contained"
                // onClick={() => {
                //   reset()
                //   onCancel && onCancel()
                // }}
                >
                  Destinação dos Recursos
                </Button>
                <Button
                className={props.classes.spacingBetweenFields}
                  xs
                  size="medium"
                  color="secondary"
                  variant="contained"
                // onClick={() => {
                //   reset()
                //   onCancel && onCancel()
                // }}
                >
                  Contrato de Garantia
                </Button>
                <Button
                className={props.classes.spacingBetweenFields}
                  xs
                  size="medium"
                  color="primary"
                  variant="contained"
                // onClick={() => {
                //   reset()
                //   onCancel && onCancel()
                // }}
                >
                  Cadastro de Controles
                </Button>
              </div>
            }
            label="Mapeamento:"
            labelPlacement="start"
          /> */}
        <div className={props.classes.mapFields}>
          <FormLabel component="legend" style={LabelMap}>
            Mapeamento:
          </FormLabel>
          <FormGroup xs={7} style={MapCheckGroup}>
            {/* <FormControlLabel
              // className={props.classes.spacingBetweenFields}
              control={ */}
            <div className={props.classes.left}>
              <CheckboxField
                color="primary"
                // inputRef={this.checkboxRef}
                // disabled={!required}
                // name={`${name}.checked`}
                name="destRecursosChk"
                id="destRecursos"
                onChange={(event, value) =>
                  onChange({ name: 'destRecursosChk', value, ...props })
                }
              />
              <Button
                // className={props.classes.spacingBetweenFields}
                //   xs
                name="destRecursos"
                size="small"
                color={destRecursosChk ? 'primary' : ''}
                disabled={destRecursosChk ? false : true}
                onClick={event => onClick({ name: 'destRecursos', ...props })}>
                Destinação de Recursos
              </Button>
            </div>
            {/* }
            // label="Destinação dos Recursos"
            /> */}
            {/* <FormControlLabel
              // className={props.classes.spacingBetweenFields}
              control={ */}
            <div className={props.classes.left}>
              <CheckboxField
                color="primary"
                // inputRef={this.checkboxRef}
                // disabled={!required}
                // name={`${name}.checked`}
                name="contrGarantiaChk"
                id="contrGarantia"
                onChange={(event, value) =>
                  onChange({ name: 'contrGarantiaChk', value })
                }
              />
              <Button
                // className={props.classes.spacingBetweenFields}
                //   xs
                name="contrGarantia"
                color={contrGarantiaChk ? 'primary' : ''}
                size="small"
                disabled={contrGarantiaChk ? false : true}
                onClick={event => onClick({ name: 'contrGarantia', ...props })}>
                Contrato de Garantia
              </Button>
            </div>
            {/* }
            // label="Contrato de Garantia"
            /> */}
            {/* <FormControlLabel
              // className={props.classes.spacingBetweenFields}
              control={ */}
            <div className={props.classes.left}>
              <CheckboxField
                color="primary"
                // inputRef={this.checkboxRef}
                // disabled={!required}
                // name={`${name}.checked`}
                name="CadControlesChk"
                id="CadControles"
                onChange={(event, value) =>
                  onChange({
                    name: 'CadControlesChk',
                    value,
                    enableRulesReset,
                    ...props
                  })
                }
              />
              <Button
                // className={props.classes.spacingBetweenFields}
                //   xs
                color={CadControlesChk ? 'primary' : ''}
                disabled={CadControlesChk ? false : true}
                name="CadControles"
                size="small"
                onClick={event =>
                  onClick({
                    name: 'CadControles',
                    handleChangeCadControl,
                    enableRules,
                    handlerControl,
                    ...props
                  })
                }>
                Cadastro de Controles
              </Button>
            </div>
            {/* }
            // label="Cadastro de Controles"
            /> */}
          </FormGroup>
          <FormControlLabel
            className={props.classes.dividers}
            // className={props.classes.spacingBetweenFields}
            control={
              <CheckboxField
                color="primary"
                // inputRef={this.checkboxRef}
                // disabled={!required}
                // name={`${name}.checked`}
                name="mapeado"
                id="mapeado"
              />
            }
            label="Mapeado"
          />
        </div>
        <FormLabel component="legend" style={LabelContract}>
          Contratos (Clique para baixar):
        </FormLabel>
        <IconButton aria-label="cloud_download" style={IconContract}>
          <CloudDownload />
        </IconButton>

        {/* <FormLabel component="legend">Tipos escolhidos:</FormLabel>
        <List component="nav" className={props.classes.containerTipoSelect}>
          <ListItem>
            <ListItemText primary={fidejussoriaOption} />
          </ListItem>
          <ListItem>
            <ListItemText primary={flutuanteOption} />
          </ListItem>
          <ListItem>
            <ListItemText primary={quirografariaOption} />
          </ListItem>
          <ListItem>
            <ListItemText primary={realOption} />
          </ListItem>
          <ListItem>
            <ListItemText primary={subordinadaOption} />
          </ListItem>
        </List> */}
        {/* <AutocompleteField
          name="groupId"
          label="Grupo"
          isClearable={true}
          xs={12}
          sm={6}
          options={options.groups}
        />
        <TextField xs name="website" label="Website" />
        <TextField xs name="relationship" label="Relação" />
        <TextField xs name="industry" label="Indústria" />

        <SelectField
          xs
          name="status"
          label="Situação"
          options={['Ativa', 'Inativa', 'Em Recuperação Judicial']}
        />
        <SelectField
          xs
          name="held"
          label="Propriedade"
          options={['Privada', 'Pública', 'Mista']}
        />
        <SelectField
          xs
          name="capital"
          label="Capital"
          options={['Aberto', 'Fechado']}
        />

        <TextField name="notes" label="Notas" multiline rowsMax="4" xs={12} /> */}
        {/* <Grid item xs={12}>
          <Grid container spacing={16}>
            <PhoneList />
          </Grid>
        </Grid> */}
        {/* <AddressList /> */}
      </Form>
    </div>
  )
}

FormTemplate.propTypes = {
  options: PropTypes.object.isRequired
}

FormTemplate.defaultProps = {
  options: {}
}

const FormView = compose(
  reduxForm({ form: 'company' }),
  withApollo,
  connect((state, { autofill }) => {
    const selector = formValueSelector('company')
    const {
      destRecursosFields,
      descricao,
      clausula,
      prazoComprovacao,
      clausulaPrazo,
      periodVerificacao,
      clausulaPeriod,
      docComprobatorios,
      clausulaDoc,
      declAssinada,
      clausulaDeclaracao,
      modelo,
      termoDefinido,
      clausulaTS,
      deducoes,
      clausulaValor,
      repassesPeriodicos,
      clausulaRepasses,
      fidejussoria,
      flutuante,
      quirografaria,
      real,
      subordinada,
      destRecursos,
      destRecursosChk,
      CadControles,
      CadControlesChk,
      cvChk,
      contaVinculadaBtn,
      showHideFormConta,
      modalGarantias,
      confirmGarantias,
      contrGarantia,
      contrGarantiaChk,
      contratoGarantiaFields,
      tipoVerificacaoCtrl,
      cadControlFields,
      ControleCadastros,
      diaUtil,
      defaultCadForm,
      dataFill
    } = selector(
      state,
      'destRecursosFields',
      'descricao',
      'clausula',
      'prazoComprovacao',
      'clausulaPrazo',
      'periodVerificacao',
      'clausulaPeriod',
      'docComprobatorios',
      'clausulaDoc',
      'declAssinada',
      'clausulaDeclaracao',
      'modelo',
      'termoDefinido',
      'clausulaTS',
      'deducoes',
      'clausulaValor',
      'repassesPeriodicos',
      'clausulaRepasses',
      'fidejussoria',
      'flutuante',
      'quirografaria',
      'real',
      'subordinada',
      'destRecursos',
      'destRecursosChk',
      'CadControles',
      'CadControlesChk',
      'cvChk',
      'contaVinculadaBtn',
      'showHideFormConta',
      'modalGarantias',
      'confirmGarantias',
      'contrGarantia',
      'contrGarantiaChk',
      'contratoGarantiaFields',
      'tipoVerificacaoCtrl',
      'diaUtilDisable',
      'cadControlFields',
      'ControleCadastros',
      'diaUtil',
      'defaultCadForm',
      'dataFill'
    )
    return {
      destRecursosFields,
      descricao,
      clausula,
      prazoComprovacao,
      clausulaPrazo,
      periodVerificacao,
      clausulaPeriod,
      docComprobatorios,
      clausulaDoc,
      declAssinada,
      clausulaDeclaracao,
      modelo,
      termoDefinido,
      clausulaTS,
      deducoes,
      clausulaValor,
      repassesPeriodicos,
      clausulaRepasses,
      fidejussoria,
      flutuante,
      quirografaria,
      real,
      subordinada,
      destRecursos,
      destRecursosChk,
      CadControles,
      CadControlesChk,
      cvChk,
      contaVinculadaBtn,
      showHideFormConta,
      modalGarantias,
      confirmGarantias,
      contrGarantia,
      contrGarantiaChk,
      contratoGarantiaFields,
      tipoVerificacaoCtrl,
      cadControlFields,
      ControleCadastros,
      diaUtil,
      defaultCadForm,
      dataFill,
      // resetField: target => {
      //   console.log(target)

      // },
      onClick: target => {
        console.log(target)

        // const resetFields = e => {

        //   console.log(e)

        //   console.log(e.e.target.value)

        //   console.log(e.index)

        //   console.log(cadControlFields)

        // //   // console.log(cadControlFields)

        // //   // switch (target.value) {
        // //   //   case 'MENSAL':
        // //   //   case 'TRIMESTRAL':
        // //   //   case 'SEMESTRAL':
        // //   //   case 'ANUAL':
        // //   //   case 'BIENAL':
        // //   //   case 'TRIENAL':

        // //   //     // copyArray.findIndex(c => c.id == target.index).diaMes = ''

        // //   //     // autofill('cadControlFields', '')

        // //   //     console.log(cadControlFields)

        // //   //     break
        // //   //   case 'QUANT DIAS ÚTEIS':
        // //   //     break
        // //   //   case 'ÚNICA':

        // //   //     break
        // //   //   case 'SEMANAL':
        // //   //   case 'QUINZENAL':

        // //   //     break
        // //   //   default:

        // //   // }

        // }

        if (target === 'resetField') {
          autofill('')
          autofill(
            'modalGarantias',
            <GuaranteeModals
              state={{ CadControles }}
              dinamycFields={{ dataFill }}
              setCloseState={setCloseState}
              title="Cadastro de Controles (Formulário):">
              <div>
                <CadControlFields
                  enableRules={target.enableRules}
                  handlerControl={target.handlerControl}
                  handleChangeCadControl={target.handleChangeCadControl}
                />
              </div>
            </GuaranteeModals>
          )
        }

        const setCloseState = () => {
          autofill('modalGarantias', false)
        }

        if (target.name === 'contaVinculadaBtn') {
          autofill('showHideFormConta', true)
        }

        if (target.name === 'destRecursos') {
          if (target.type === 'DEB' || target.type === 'NPC') {
            autofill(
              'modalGarantias',
              <GuaranteeModals
                state={{ destRecursos }}
                setCloseState={setCloseState}
                title="Destinação dos Recursos (Formulário):">
                <div className={target.classes.dialogRecursos}>
                  <DestRecursosDebNp />
                </div>
              </GuaranteeModals>
            )
          } else if (target.type === 'CRI' || target.type === 'CRA') {
            autofill(
              'modalGarantias',
              <GuaranteeModals
                state={{ destRecursos }}
                setCloseState={setCloseState}
                title="Destinação dos Recursos (Formulário):">
                <div className={target.classes.dialogRecursos}>
                  <DestRecursosCriaCra />
                </div>
              </GuaranteeModals>
            )
          }
        } else if (target.name === 'contrGarantia') {
          autofill(
            'modalGarantias',
            <GuaranteeModals
              state={{ CadControles }}
              dinamycFields={{ dataFill }}
              setCloseState={setCloseState}
              title="Contrato de Garantia (Formulário):">
              <div className={target.classes.dialogControles}>
                <ContratoGarantiaFields classes={target.classes} />
              </div>
            </GuaranteeModals>
          )
        } else if (target.name === 'CadControles') {
          console.log(target.onClick)
          console.log(cadControlFields)
          autofill(
            'modalGarantias',
            <GuaranteeModals
              state={{ CadControles }}
              dinamycFields={{ dataFill }}
              setCloseState={setCloseState}
              title="Cadastro de Controles (Formulário):">
              <div className={target.classes.dialogControles}>
                <CadControlFields
                  enableRules={target.enableRules}
                  handlerControl={target.handlerControl}
                  handleChangeCadControl={target.handleChangeCadControl}
                />
              </div>
            </GuaranteeModals>
          )
        }
      },
      onChange: target => {
        console.log(target.name)

        let element = target.name

        const delFormData = resetFunction => {
          if (element === 'destRecursosChk') {
            autofill('descricao', '')
            autofill('clausula', '')
            autofill('prazoComprovacao', '')
            autofill('clausulaPrazo', '')
            autofill('periodVerificacao', '')
            autofill('clausulaPeriod', '')
            autofill('docComprobatorios', '')
            autofill('clausulaDoc', '')
            autofill('declAssinada', '')
            autofill('clausulaDeclaracao', '')
            autofill('modelo', '')
            autofill('termoDefinido', '')
            autofill('clausulaTS', '')
            autofill('deducoes', '')
            autofill('clausulaValor', '')
            autofill('repassesPeriodicos', '')
            autofill('clausulaRepasses', '')
            autofill('modalGarantias', false)
          } else if (element === 'cvChk') {
            autofill('showHideFormConta', false)
            autofill('modalGarantias', false)
          } else if (element === 'contrGarantiaChk') {
            autofill('contratoGarantiaFields', '')
            autofill('modalGarantias', false)
          } else if (element === 'CadControlesChk') {
            resetFunction()
            autofill('cadControlFields', '')
            autofill('modalGarantias', false)
          }
        }
        const handleCloseState = () => {
          autofill('modalGarantias', false)
          autofill(element, true)
        }

        let destRecFields =
          descricao ||
          clausula ||
          prazoComprovacao ||
          clausulaPrazo ||
          periodVerificacao ||
          clausulaPeriod ||
          docComprobatorios ||
          clausulaDoc ||
          declAssinada ||
          clausulaDeclaracao ||
          modelo ||
          termoDefinido ||
          clausulaTS ||
          deducoes ||
          clausulaValor ||
          repassesPeriodicos ||
          clausulaRepasses

        if (destRecFields) {
          if (target.name === 'destRecursosChk' && !target.value) {
            autofill(
              'modalGarantias',
              <GuaranteeConfirmBox
                state={{ destRecursosChk }}
                setCloseState={handleCloseState}
                confirm={delFormData}
                title="ATENÇÃO!">
                <Typography variant="h6">
                  Desmarcando a opção, todos os dados serão deletados. Tem
                  certeza?
                </Typography>
              </GuaranteeConfirmBox>
            )
          }
        }

        if (target.name === 'cvChk' && !target.value) {
          if (showHideFormConta) {
            autofill(
              'modalGarantias',
              <GuaranteeConfirmBox
                state={{ destRecursosChk }}
                setCloseState={handleCloseState}
                confirm={delFormData}
                title="ATENÇÃO!">
                <Typography variant="h6">
                  Desmarcando a opção, todos os dados serão deletados. Tem
                  certeza?
                </Typography>
              </GuaranteeConfirmBox>
            )
          }
        }

        if (contratoGarantiaFields) {
          if (element === 'contrGarantiaChk' && !target.value) {
            autofill(
              'modalGarantias',
              <GuaranteeConfirmBox
                state={{ destRecursosChk }}
                setCloseState={handleCloseState}
                confirm={delFormData}
                title="ATENÇÃO!">
                <Typography variant="h6">
                  Desmarcando a opção, todos os dados serão deletados. Tem
                  certeza?
                </Typography>
              </GuaranteeConfirmBox>
            )
          }
        }

        if (cadControlFields) {
          if (element === 'CadControlesChk' && !target.value) {
            autofill(
              'modalGarantias',
              <GuaranteeConfirmBox
                state={{ destRecursosChk }}
                enableRulesReset={target.enableRulesReset}
                setCloseState={handleCloseState}
                confirm={delFormData}
                title="ATENÇÃO!">
                <Typography variant="h6">
                  Desmarcando a opção, todos os dados serão deletados. Tem
                  certeza?
                </Typography>
              </GuaranteeConfirmBox>
            )
          }
        }
      }
    }
  }),
  withHandlers({
    onCnpjBlur: ({
      client,
      initialized,
      autofill,
      isLoading,
      dispatch
    }) => async (event, newValue, previousValue, name) => {
      const cnpj = newValue.replace(/\D/g, '')
      if (initialized || !cnpj) return
      dispatch({
        type: 'APP_LOADING',
        payload: true
      })
      const { data } = await client.query({
        query: gql`
          query find($cnpj: String!) {
            findCompany(cnpj: $cnpj)
          }
        `,
        variables: { cnpj }
      })
      const company = data.findCompany
      for (var prop in data.findCompany) {
        if (company.hasOwnProperty(prop)) {
          console.log('FormView#onCnpjBlur', prop, company[prop])
          autofill(prop, company[prop])
        }
      }
      dispatch({
        type: 'APP_LOADING',
        payload: false
      })
    }
  })
)(FormTemplate)

export default FormView
