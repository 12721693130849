import React, { useState } from 'react'
import { lifecycle, withState } from 'recompose'
import { FieldArray, reduxForm, change } from 'redux-form'
import validate from './validate'

import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'

import { TextField, SelectField, DateField, NumberField } from '@ftrustee/ui'

import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'

import TipoVerificacaoCtrl from './TipoVerificacaoCtrl'
import TableCadControl from './TableCadControl'

// let date

// const handleInitialDate = (value) => {
//   console.log(value)
//   date = value
//   // console.log(date)
// }

const renderRefs = lifecycle({
  componentDidMount() {
    this.props.fields.length === 0 && this.props.fields.push()
  }
})(
  ({
    fields,
    enableRules,
    handleChangeCadControl,
    handlerControl,
    meta: { error, submitFailed },
    ...props
  }) => {
    const [date, setDate] = useState(date)

    console.log(date)

    return (
      <React.Fragment>
        {fields.map((member, index) => (
          <div>
            <Grid style={{ height: 0 }} item xs={12}>
              <IconButton
                color="secondary"
                aria-label="adicionar"
                // style={{ marginTop: 10, marginLeft: '93%' }}
                style={{
                  width: '50px',
                  position: 'relative',
                  left: '100%'
                }}
                // onClick={() =>
                //   index > 0 ? (handleObject(index), fields.remove(index)) : (handleObject(index, fields), fields.push())
                // }
                onClick={(event, value) =>
                  handlerControl({
                    event,
                    fields,
                    value,
                    index,
                    enableRules
                  })
                }
                // onClick={() => resetFields(index, fields, enableRules)}
              >
                <Icon>{index > 0 ? 'remove_circle' : 'add_circle'}</Icon>
              </IconButton>
            </Grid>
            <ExpansionPanel
              defaultExpanded
              // className={props.classes.root}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                {/* <Typography variant="title">
              Formulário {index + 1}
              </Typography> */}
                <Grid item xs={12} spacing={16}>
                  <Typography variant="title">
                    <TextField
                      className="custom-input"
                      name={`${member}.name`}
                      // type="text"
                      placeholder={'Formulário ' + (index + 1)}
                      InputProps={{
                        // readOnly: true,
                        disableUnderline: true
                      }}
                    />
                  </Typography>
                </Grid>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid item xs={12} key={index}>
                  <Grid
                    container
                    spacing={16}
                    xs={12}
                    direction="column"
                    justify="space-between"
                    alignItems="flex-start">
                    <SelectField
                      xs={12}
                      name={`${member}.value`}
                      // className={props.classes.mapFields}
                      placeholder="Referência:"
                      type="referencia"
                      options={[
                        'Destinação de recursos',
                        'Aditamento Periódico',
                        'Pagamento PMT',
                        'Enviar Informação',
                        'Receber Informação',
                        'Formalização de Documento',
                        'Verificação'
                      ]}
                    />
                  </Grid>
                  <Grid
                    style={{ marginLeft: 0, marginTop: '10px' }}
                    container
                    spacing={16}
                    xs={12}>
                    {/* <TipoVerificacaoCtrl
                    index={index}
                    member={member}
                    name={`${member}.camposVerificacao`}
                  /> */}
                    <SelectField
                      xs={3}
                      name={`${member}.periodicidade`}
                      placeholder="Periodicidade: "
                      options={[
                        'Mensal',
                        'Única',
                        'Anual',
                        'Trimestral',
                        'Semestral',
                        'Bienal',
                        'Semanal',
                        'Quinzenal',
                        'Diário',
                        'Trienal'
                      ]}
                      // onChange={(event, value) => this.handleOnChange({ event, value, classes })}
                      // onChange={(event, value) =>
                      //   handleChange({ event, value, index, member, enableRules })
                      // }
                      onChange={(event, value) =>
                        handleChangeCadControl({
                          event,
                          value,
                          index,
                          enableRules,
                          name: 'periodicidade'
                        })
                      }
                    />
                    <SelectField
                      xs
                      name={`${member}.diaUtil`}
                      placeholder="Dia útil: "
                      options={[
                        '1° dia útil do mês',
                        'Útilmo dia útil do mês',
                        'Escolher dia',
                        'Dia útil'
                      ]}
                      onChange={(event, value) =>
                        handleChangeCadControl({
                          event,
                          value,
                          index,
                          enableRules,
                          name: 'diaUtil'
                        })
                      }
                      disabled={enableRules[index].diaUtilState}
                      // disabled={variablesBoolean[index].indexOf(0)}
                      // onChange={(event, value) => this.handleSelect({ event, value })}
                      // onChange={(event, value) => handleUtil({ event, value, variablesBoolean })}
                    />
                    <NumberField
                      xs
                      type="number"
                      name={`${member}.diaMes`}
                      InputProps={{
                        inputProps: { min: 0, max: 31, length: 2 }
                      }}
                      placeholder="Escolha dia:"
                      helperText="Do dia 1 ao 31 **"
                      // disabled={diaMesState === undefined ? true : diaMesState}
                      // disabled={variablesBoolean[index].indexOf(1)}
                      disabled={enableRules[index].diaMesState}
                    />
                    <SelectField
                      xs
                      name={`${member}.diasSemana`}
                      placeholder="selecione dia da semana:"
                      options={[
                        'Segunda',
                        'Terça',
                        'Quarta',
                        'Quinta',
                        'Sexta'
                      ]}
                      // disabled={diasSemanaState === undefined ? true : diasSemanaState}
                      // disabled={variablesBoolean[index].indexOf(2)}
                      disabled={enableRules[index].diasSemanaState}
                    />

                    <NumberField
                      xs
                      name={`${member}.quantsUteis`}
                      placeholder="Quant. dias:"
                      // helperText="Do dia 1 ao 31 **"
                      // disabled={diaMesState === undefined ? true : diaMesState}
                      // disabled={variablesBoolean[index].indexOf(1)}
                      disabled={enableRules[index].quantDiasState}
                    />
                  </Grid>
                  <Grid
                    style={{ paddingTop: '0px' }}
                    container
                    spacing={16}
                    xs={12}
                    direction="column"
                    justify="space-between"
                    alignItems="flex-start">
                    <DateField
                      // className={classes.dateField}
                      xs={3}
                      // name={'PrimeiraVerificacao' + this.props.index}
                      name={`${member}.primeiraVerificacao`}
                      placeholder="Primeira data verificação:"
                      onChange={(e, value) => setDate(value)}
                    />
                    {/* {dynamicForm} */}
                    <TextField
                      // className={props.classes.alignLeftForm}
                      style={{ width: '100%' }}
                      xs={3}
                      name={`${member}.contrato`}
                      // name={'contrato' + this.props.index}
                      placeholder="Contrato"
                    />
                    <TextField
                      // className={props.classes.alignLeftForm}
                      style={{
                        width: '100%',
                        marginBottom: '25px'
                      }}
                      xs={3}
                      // name={'descObrigacao' + this.props.index}
                      name={`${member}.descObrigacao`}
                      placeholder="Descrição Obrigação"
                    />
                    <Grid item xs={12} className="TableCadCrtl">
                      <TableCadControl
                        index={index}
                        member={member}
                        date={date}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        ))}
      </React.Fragment>
    )
  }
)

let CadControlFields = props => {
  const {
    enableRules,
    handleChangeCadControl,
    handlerControl,
    copyState,
    pristine,
    reset,
    submitting
  } = props

  return (
    // <Grid item xs={12}>
    <Grid container spacing={16}>
      <FieldArray
        name="cadControlFields"
        enableRules={enableRules}
        handleChangeCadControl={handleChangeCadControl}
        handlerControl={handlerControl}
        copyState={copyState}
        component={renderRefs}
      />
    </Grid>
    // </Grid>
  )
}

// export default (CadControlFields = reduxForm({
//   form: 'company',// a unique identifier for this form
//   validate
// })(CadControlFields))

// const CadControlFields = () => (
//   <FieldArray name="cadControlFields" component={renderMembers} />
// )

export default CadControlFields
