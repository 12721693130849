import React from 'react'
import { lifecycle } from 'recompose'
import { FieldArray } from 'redux-form'

import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'

import { TextField, SelectField, DateField, NumberField } from '@ftrustee/ui'

import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'

import TipoVerificacaoCtrl from './TipoVerificacaoCtrl'
import TableCadControl from './TableCadControl'

const renderRefs = lifecycle({
  componentDidMount() {
    this.props.fields.length === 0 && this.props.fields.push()
  }
})(({ fields, meta: { error, submitFailed }, ...props }) => {
  // const options = [
  //   {
  //     value: 'blocked',
  //     label: 'Bloqueada'
  //   },
  //   {
  //     value: 'unblocked',
  //     label: 'Desbloqueada'
  //   }
  // ]

  return (
    <React.Fragment>
      {fields.map((member, index) => (
        <div>
          <Grid style={{ height: 0 }} item xs={12}>
            <IconButton
              color="secondary"
              aria-label="adicionar"
              // style={{ marginTop: 10, marginLeft: '93%' }}
              style={{
                width: '50px',
                position: 'relative',
                left: '100%'
              }}
              onClick={() =>
                index > 0 ? fields.remove(index) : fields.push()
              }>
              <Icon>{index > 0 ? 'remove_circle' : 'add_circle'}</Icon>
            </IconButton>
          </Grid>
          <ExpansionPanel
            defaultExpanded
            // className={props.classes.root}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              {/* <Typography variant="title">Formulário {index + 1}</Typography> */}
              <Grid item xs={12} spacing={16}>
                <Typography variant="title">
                  <TextField
                    className="custom-input"
                    name={`${member}.name`}
                    // type="text"
                    placeholder={'Formulário ' + (index + 1)}
                    InputProps={{
                      // readOnly: true,
                      disableUnderline: true
                    }}
                  />
                </Typography>
              </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid item xs={12} key={index}>
                <Grid
                  container
                  spacing={16}
                  xs={12}
                  direction="column"
                  justify="space-between"
                  alignItems="flex-start">
                  <SelectField
                    xs={12}
                    name={`${member}.value`}
                    // className={props.classes.mapFields}
                    placeholder="Referência:"
                    type="referencia"
                    options={[
                      'Destinação de recursos',
                      'Aditamento Periódico',
                      'Pagamento PMT',
                      'Enviar Informação',
                      'Receber Informação',
                      'Formalização de Documento ou Verificação'
                    ]}
                  />
                  <SelectField
                    // className={props.classes.modalSelect}
                    xs={3}
                    // name={'periodicidade' + this.props.index}
                    name={`${member}.periodicidade`}
                    placeholder="Periodicidade: "
                    options={[
                      'Mensal',
                      'Única',
                      'Anual',
                      'Trimestral',
                      'Semestral',
                      'Bienal',
                      'Semanal',
                      'Quinzenal',
                      'Diário',
                      'Trienal'
                    ]}
                    // onChange={(event, value) => handleChange({ event, value })}
                    // onChange={(event, value) => (dynamicOption = value)}
                  />
                  <DateField
                    // className={classes.dateField}
                    xs={3}
                    // name={'PrimeiraVerificacao' + this.props.index}
                    name={`${member}.primeiraVerificacao`}
                    placeholder="Primeira data verificação:"
                    // onChange={(e, value) => this.handleInitialDate({ e, value })}
                  />
                  {/* {dynamicForm} */}
                  <TextField
                    // className={props.classes.alignLeftForm}
                    style={{ width: '100%' }}
                    xs={3}
                    name={`${member}.contrato`}
                    // name={'contrato' + this.props.index}
                    placeholder="Contrato"
                  />
                  <TextField
                    // className={props.classes.alignLeftForm}
                    style={{
                      width: '100%',
                      marginBottom: '25px'
                    }}
                    xs={3}
                    // name={'descObrigacao' + this.props.index}
                    name={`${member}.descObrigacao`}
                    placeholder="Descrição Obrigação"
                  />
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      ))}
    </React.Fragment>
  )
})

const ContratoGarantiaFields = () => (
  <FieldArray name="contratoGarantiaFields" component={renderRefs} />
)

export default ContratoGarantiaFields
